import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import Old2005BlogPage from "../../components/Old2005BlogPage";

const OldBlogPage = () => (
  <Layout>
    <Seo title="Old 2005 Blog—April 2009" />
	<Old2005BlogPage>
		<h2>April 2009</h2>



		<h3 className="blogdate">
		Thursday, April 09, 2009
		</h3>






		<h4 className="blogitemtitle">
		Wind whips away good sense
		</h4>





		   <div className="blogitembody">
		   It's been a week or two - it's now time for our regular Antarctic wind to rip its way through Island Bay and northwards to the rest of Wellington.  I'm typing now with icicle fingers.<br /><br />On the walk home from the grocery store I came across an elderly lady, probably in her eighties, coming out from the side of her carport to release a "rubbish kite" into the wind.  I think it was a plastic bag that probably once held some potatoes.<br /><br />"It's not mine, so I'll let the wind take it," she explained to me, setting the kite free as I continued past her.<br /><br />Great.  Nice one!  So, what you're saying is:  if some rubbish blows into your yard, then you don't have/want to deal with it; you'll toss it out past the border of your yard so that the wind can blow it into the next person's yard.<br /><br />Maybe one of her sons lives down the street, and this bag will land in his yard.  I can imagine how he'll act when he finds it.<br /><br />My fingers have warmed up a bit.  I'll go make some guacamole and salsa now, with thoughts of warm Mexico.
		   </div>
		   <div className="blogitemfooter">
		   posted by Jesse @ 1:53 PM &nbsp;
		   </div>





		<p>
			<Link to="/blog2005/">Go back to the blog index page</Link>
		</p>
</Old2005BlogPage>
  </Layout>
)

export default OldBlogPage;
